/**
 * Init Utils
 */

/**
 * Add identifiers for first and last block
 */
const initUtils = () => {
	if (
		!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		document.body.classList.add('is_pc')
	} else {
		document.body.classList.add('is_mobile')
	}

	jQuery(function ($) {
		minHeight()
		$(window).resize(function () {
			minHeight()
		})
	})

	function minHeight() {
		if (jQuery('.site-content').length) {
			var heighttarget = parseInt(jQuery(window).height(), 10)

			if (jQuery('.site-header').length) {
				heighttarget =
					heighttarget - parseInt(jQuery('.site-header').outerHeight(true), 10)
			}

			if (jQuery('.site-breadcrumbs').length) {
				heighttarget =
					heighttarget -
					parseInt(jQuery('.site-breadcrumbs').outerHeight(true), 10)
			}

			if (jQuery('.site-footer').length) {
				heighttarget =
					heighttarget - parseInt(jQuery('.site-footer').outerHeight(true), 10)
			}

			jQuery('.site-content').css('min-height', heighttarget)
		}
	}

	// import ResponsiveAutoHeight from 'responsive-auto-height';
	let autoheight = document.querySelector('.js-auto-height')
	if (autoheight) {
		new ResponsiveAutoHeight('.js-auto-height')
	}
}
initUtils()
