/**
 * Scroll Utils
 */

/**
 * Add identifiers for first and last block
 */
const scrollUtils = () => {
	const body = document.body
	let lastScroll = 0
	let didScroll

	body.classList.add('scroll-up', 'scroll-top')

	// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
	window.addEventListener(
		'scroll',
		function () {
			didScroll = true
		},
		false
	)
	setInterval(function () {
		if (didScroll) {
			var currentScroll =
				window.pageYOffset || document.documentElement.scrollTop

			currentScroll = 10 * Math.floor(currentScroll / 10)
			// console.log(currentScroll)
			if (
				currentScroll > lastScroll &&
				!body.classList.contains('scroll-down')
			) {
				// downscroll code
				body.classList.remove('scroll-down', 'scroll-up')
				body.classList.add('scroll-down')
			} else if (
				currentScroll < lastScroll &&
				body.classList.contains('scroll-down')
			) {
				// upscroll code
				body.classList.remove('scroll-down', 'scroll-up')
				body.classList.add('scroll-up')
			}

			if (currentScroll >= 0 && currentScroll < 100) {
				// scroll top code
				// document.body.classList.remove('scroll-top')
				body.classList.add('scroll-top')
			} else {
				body.classList.remove('scroll-top')
			}
			lastScroll = currentScroll <= 0 ? 0 : currentScroll // For Mobile or negative scrolling
			didScroll = false
		}
	}, 250)
}
scrollUtils()
