/**
 * Accordeon
 */

/**
 * Add body class .has-no-hero-background
 */
const accordeon = () => {
	if (document.querySelector('.accordeon')) {
		function selects() {
			var ele = document.querySelectorAll('.accordeon_input')
			for (var i = 0; i < ele.length; i++) {
				if (ele[i].type == 'checkbox') ele[i].checked = true
			}

			var ele = document.querySelectorAll('.accordeon__image')
			for (var i = 0; i < ele.length; i++) {
				ele[i].classList.add('checked')
			}
		}
		document.querySelectorAll('.accordeon_input').forEach((item) => {
			item.addEventListener('click', (event) => {
				event.checked = false
				selects()
				if (item.type == 'checkbox') item.checked = false
				if (item.dataset.id) {
					const el2 = document.querySelector(
						'[data-idimage="' + item.dataset.id + '"]'
					)

					el2.classList.remove('checked')
				}
				// if (ele[i].type == 'checkbox') ele[i].checked = true
			})
		})

		// var ele = document.getElementsByName('chk')
		// for (var i = 0; i < ele.length; i++) {
		//     if (ele[i].type == 'checkbox') ele[i].checked = true
		//     ele[i].onclick = function () {

		//     }
		// }
	}
}
accordeon()
