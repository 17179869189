/* ==========================================================================
  main.js
========================================================================== */

'use strict'
/* global fitvids, cssVars */

window.onload = function () {
	document.body.classList.add('loaded')
}

/**
 * Init responsive videos
 */
fitvids()

let offsetMenu = -180

if (location.hash) {
	setTimeout(function () {
		window.scrollTo(0, 0)
	}, 1)

	setTimeout(function () {
		jQuery.scrollTo(location.hash, 500, {
			offset: offsetMenu,
		})
	}, 600)
}

// Select all links with hashes
jQuery(
	'.site-header__main ul li a[href*="#"], .site-header__branding a[href*="#"], .site-header__legapole a[href*="#"]'
)
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	// On-page links
	.on('click', function (event) {
		if (
			location.pathname.replace(/^\//, '') ==
				this.pathname.replace(/^\//, '') &&
			location.hostname == this.hostname
		) {
			// Figure out element to scroll to
			var target = jQuery(this.hash)

			target = target.length
				? target
				: jQuery('[name=' + this.hash.slice(1) + ']')
			// Does a scroll target exist?
			if (target.length) {
				// Only prevent default if animation is actually gonna happen
				event.preventDefault()

				jQuery.scrollTo(target, 500, {
					offset: offsetMenu,
				})
			}
		}
	})
