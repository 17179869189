/**
 * Back to top
 */

/**
 * Add body class .has-no-hero-background
 */
const annimateBackToTop = () => {
	if (document.querySelector('.backtotop')) {
		var backtotop = document.querySelector('.backtotop')
		backtotop.onclick = function () {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}

		// jQuery(function ($) {
		// 	if ($('.backtotop').length) {
		// 		$('.backtotop').click(function () {
		// 			$('body,html').animate(
		// 				{
		// 					scrollTop: 0,
		// 				},
		// 				800
		// 			)
		// 		})
		// 	}
		// })
	}
}
annimateBackToTop()
